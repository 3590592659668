import React from 'react';
import {Helmet} from "react-helmet";
import Layout from "../../components/layout";
import { Link } from 'gatsby';
import Iframe from 'react-iframe';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/src/styles.js';
import { Title, Twitter } from "../../components/Globals";
import { FaAngleDoubleDown } from "react-icons/fa";

const SpendAnalysisPage = () => {
    const ref = React.createRef();

    const arrowClick = () =>
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
  return (
        <Layout>
           
            <div className="spend-analysis-background flex flex-col">
            <Helmet>
        <title>Spend Analysis Consulting | Cymetrix Software</title>
        <meta name="description" content="Cymetrix Software offers Spend Analysis Services to help organizations in achieving digital transformtation in procurement and enable them to get better insights that drives next level of value creation."/>
      <meta name="keywords" content="cymetrix,spend analysis, spend analytics, spend analysis services, spend analytics services, spend analysis consulting, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
        <link rel="canonical" href="https://www.cymetrixsoft.com/services/spend-analysis/" />
      </Helmet>
            <h1 className=" xl:text-4xl xl:m-56  md:m-16 sm:m-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl
                text-white text-uppercase text-center">
                    IDENTIFY NEW AREAS OF OPERATIONAL SAVINGS
                    </h1>
                <button class="focus:outline-none " >
         <FaAngleDoubleDown className=" w-32 h-20 py-5 text-white" onClick={arrowClick}/>
        </button>
            </div>
            <div className="relative max-w-6xl mx-auto" style={{backgroundColor: '#f6f6f6'}} ref={ref}>
                <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24 justify-center">
                    <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
                        <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                            <img
                                className="h-64 w-64"
                                src={require('./../../images/services/saleforceApiImg.png')}
                                alt="Hero"
                                />
                        </div>
                    </div>
                    <div className="sma:mx-3 relative lg:w-8/12 flex-shrink-0 lg:text-lef mx-auto justify-center" >
                        <h1 className="font-black text-3xl leading-snug max-w-3xl text-hover font-serif">
                            Spend Analysis Consultant
                        </h1><br />
                        <hr className="border-solid border-black mb-5" style={{borderWidth: 1}}/>
                        <p className="font-hairline text-justify">Spend Analysis is the way toward collecting, purifying, classifying, and analyzing expenditure data to diminish procurement costs, improving productivity, and checking controls and consistency.
 <br /><br />

                        When you can visualize all your data, you can make better sourcing and supplier decisions. For example: do you have too many suppliers? Do you really know which suppliers you are spending the most with? Are your top suppliers compliant with your organizational standards and relevant regulations? Getting the answers begins with really seeing your data. After all, you can’t save what you can’t see. <br />
                        “Simplifying doing business with innovative IT solutions”.
                        </p>
                    </div>
                </div>

                <div className="flex flex-col mx-auto items-center rounded-lg pb-10">
                    <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1"/>
                    <div className="px-6 py-4 bg-white  text-gray-700">
                        <h4 class="text-lg"><b>What We Offer</b></h4>
                        <p> We classify data according to company and industry standards, and based on that builds significant dashboards. Our sharp, insightful, actionable spend analysis provides the spend visibility, helps customers to make better, faster, more assertive, significant buying decisions – and negotiate with more power.</p>
                    </div>
                </div>
            </div>

            <section className="about-second-background h-auto mx-auto  ">
                <div className="max-w-6xl flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14 ">
                    <h1 className="md:text-2xl lg:text-3xl text-white">Spend Analysis and its Significance</h1>
                    <div className="md:px-3 lg:px-6 flex ">
                        <div className="bg-transparent flex flex-col mx-auto lg:px-6 lg:py-10 border border-primary-500 rounded-lg mt-12 " style={{borderWidth: 1}}>
                            <div className="text-white mt-2 mx-2 md:text-lg">Cymetrix Software is a<Link to="/services/salesforce-development-services" class="underline ">  offshore software development company </Link> and knows how to do spend analysis and offers a set of services help to generate insights and value to your spend analysis in procurement.</div>
                            <div className="mt-2 text-base mx-5 text-gray-300">
                                <ul className="list-disc">
                                    <li className="mt-2 text-gray-400"><span className="text-lg text-white">Identify Data Sources</span></li>
                                    <li className="mt-2 text-gray-400"><span className="text-lg text-white">Data Extraction</span></li>
                                    <li className="mt-2 text-gray-400"><span className="text-lg text-white">Cleansing of Data</span></li>
                                    <li className="mt-2 text-gray-400"><span className="text-lg text-white">Data Enrichmen</span></li>
                                    </ul>
                                    <ul className="list-disc px-5">
                                    <li className="mt-2 text-gray-400"><span className="text-lg text-white">Enhanced vendor normalization</span></li>
                                    <li className="mt-2 text-gray-400"><span className="text-lg text-white">Data enhancement including D&B scores, diversity and sustainability information.</span></li>
                                </ul>
                                <ul className="list-disc">
                                    <li className="mt-2 text-gray-400"><span className="text-lg text-white">Classification</span></li>
                                    </ul>
                                <ul className="list-disc px-5">
                                <li className="mt-2 text-gray-400"><span className="text-lg text-white">Evaluation of elements across suppliers and commodities</span></li>
                                    <li className="mt-2 text-gray-400"><span className="text-lg text-white">Classification accuracy of more than 95 %</span></li>
                                </ul>
                                <ul className="list-disc ">
                                <li className="mt-2 text-gray-400"><span className="text-lg text-white">Analysis of data</span></li>
                                     </ul>
                                <ul className="list-disc px-5">
                                <li className="mt-2 text-gray-400"><span className="text-lg text-white">Analyze and recommend savings</span></li>
                                    <li className="mt-2 text-gray-400"><span className="text-lg text-white">Visualization and Dashboards</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col items-center sm:flex-row justify-center ">
                  <Link to="/contact-us" className="uppercase px-8 lg:px-10 py-3 rounded bg-transparent text-white focus:shadow-outline border border-yellow-500 hover:border-blue-100  transition duration-300 mb-8 ">
                    Contact Cymetrix
                  </Link>
                </div>
            </section>
            <div className="flex flex-col mx-auto h-70 max-w-6xl my-5">
        <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
        <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
                
            </div>
            <Twitter />

            
            </div>
        </Layout>
    );
}

export default SpendAnalysisPage;